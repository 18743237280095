.input-group {
  position: relative;
  margin-bottom: 12px;
	width: 100%;
	@include breakpoint-up('lg') {
		margin-bottom: 25px;
	}
	&.input-group--checkbox {
		display: flex;
		align-items: center;
	}
}

.input-group--ischeckout {
  width: 50%;
  margin-bottom: 0;
}

.label {
	margin-bottom: 10px;
	@include breakpoint-up('md') {
		margin-bottom: 15px;
	}
	&--block {
		display: block;
	}
}

.textarea {
	font-size: 16px;
  width: 100%;
  height: 60px;
  border: 1px solid $fawn50-2;
  border-radius: 12px;
	padding: 18px;
	color: $sitkablue;
}

.input {
	font-size: 16px;
  width: 100%;
  height: 60px;
  border: 1px solid $fawn50-2;
  border-radius: 12px;
	padding: 0 18px;
	color: $sitkablue;
	&[type="checkbox"] {
		flex-basis: 26px;
		width: 26px;
		height: 26px;
		margin-right: 10px;
		~ label {
			flex: 1;
			font-size: 18px;
			line-height: 150%;
			position: relative;
		}
	}
}

.input-group--flex {
	display: flex;
}

.input-group--wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.input-group {
		width: 100%;
		@include breakpoint-up('md') {
			width: calc(50% - 17.5px);
		}
	}
}